import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getStartForm } from '../get-start-form/actions';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { TaskForm } from 'reducers/taskFormType';

import { fromNullable } from 'fp-ts/lib/Option';
import DeferredSpinner from 'components/DeferredSpinner';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import StartFormForm from './StartForm';
import ErrorPage from 'remoteStatus/one/components/pages/BaseErrorPage';
import produce from 'immer';
import fromEntries from 'util/fromentries';
import FetchInitialQueryParams from './FetchInitialQueryParams';
import memoizeOne from 'memoize-one';
import { createStartFormSelector } from '../get-start-form/createStartFormSelector';
import Warning from '@mui/icons-material/Warning';

interface StartFormProps {
    businessKey: string;
    queryParams?: {};
    offlineMode?: boolean;
    interceptSuccess?: (redirect?: string) => void;
    useInternalFormId?: true;
    renderBeforeOutcomes?: () => JSX.Element;
    taskFormKey?: string;
    isPopover?: boolean;
}

const StartForm = React.memo((props: StartFormProps) => {
    const { taskFormKey } = props;
    const processDefinitionId = useAppSelector((state: RootState) => {
        return fromNullable(state.bpm.processDefinitions.byId[props.businessKey])
            .mapNullable((d) => d.id)
            .toNullable();
    });
    const formSelector = useMemo(createStartFormSelector, []);
    const formRemoteData = useAppSelector((state: RootState) => {
        return formSelector(state, processDefinitionId ?? '', taskFormKey);
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (processDefinitionId) {
            dispatch(getStartForm(processDefinitionId, taskFormKey));
        }
    }, [dispatch, processDefinitionId, taskFormKey]);
    useEffect(() => {
        if (formRemoteData.isFailure()) {
            console.error(formRemoteData.error);
        }
    }, [formRemoteData]);

    const getPassthroughValues = useMemo(
        () =>
            memoizeOne((taskForm: TaskForm, queryParams?: {}) => {
                // any query-parameters that don't map onto fields
                return queryParams
                    ? fromEntries(
                          Object.entries(queryParams).filter(([k, v]) => {
                              return !taskForm.fields.some((f) => f.id === k);
                          }),
                      )
                    : undefined;
            }),
        [],
    );

    const getTaskFormInitializedByQueryParams = useMemo(
        () =>
            memoizeOne((taskForm: TaskForm, queryParams?: {}) => {
                return queryParams
                    ? produce(taskForm, (draftTaskForm) => {
                          draftTaskForm.fields.forEach((f) => {
                              const value = queryParams[f.id];
                              if (f.id && typeof value !== 'undefined') {
                                  switch (f.type) {
                                      case 'boolean':
                                          f.value = typeof value === 'string' && value.toLowerCase().trim() !== 'false';
                                          break;
                                      case 'integer':
                                          f.value = parseInt(value, 10);
                                          break;
                                      case 'dropdown':
                                      case 'radio-buttons':
                                      case 'value-set-multi-select':
                                      case 'entity-multi-select-chip':
                                      case 'value-set-multi-checkbox':
                                      case 'table':
                                      case 'multiple-entity-typeahead':
                                          f.value = JSON.parse(value);
                                          break;
                                      default:
                                          f.value = value;
                                  }
                              }
                          });
                          return draftTaskForm;
                      })
                    : taskForm;
            }),
        [],
    );

    return formRemoteData.foldL(
        () => (processDefinitionId ? <DeferredSpinner /> : null),
        () => <DeferredSpinner />,
        (err) => {
            if (err.status) {
                return <ServerError code={err.status} message={err.message} />;
            }
            if (err.name && err.name.startsWith('AjaxError')) {
                return <NetworkUnavailable retry={() => dispatch(getStartForm(processDefinitionId ?? ''))} />;
            }
            return (
                <ErrorPage
                    Icon={Warning}
                    headingText="Form Configuration Error"
                    subText="Check console to see the full error and stack trace."
                />
            );
        },
        (taskForm) => {
            const taskFormInitialized = getTaskFormInitializedByQueryParams(taskForm, props.queryParams);
            // any query-parameters that don't map onto fields, we just append to our submission data
            const passThroughValues = getPassthroughValues(taskForm, props.queryParams);
            return (
                <>
                    <FetchInitialQueryParams taskForm={taskForm} queryParams={props.queryParams} />
                    <StartFormForm
                        isPopover={props.isPopover}
                        taskFormKey={props.taskFormKey}
                        useInternalFormId={props.useInternalFormId}
                        offlineMode={props.offlineMode}
                        businessKey={props.businessKey}
                        formDefinition={taskFormInitialized}
                        passThroughValues={passThroughValues}
                        interceptSuccess={props.interceptSuccess}
                        renderBeforeOutcomes={props.renderBeforeOutcomes}
                    />
                </>
            );
        },
    );
});
export default StartForm;
