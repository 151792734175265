import { Button, DialogActions, DialogContent, DialogTitle, IconButton, useTheme } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Popup from 'components/Popup';
import React, { useEffect } from 'react';
import { services } from 'sideEffect/services';
import useService from 'util/hooks/useService';
const DeleteIconPopup: React.FC<{
    decisionTableKey: string;
    onDeleteSuccess?: () => void;
}> = ({ decisionTableKey, onDeleteSuccess }) => {
    const theme = useTheme();
    const [state, requestDelete, { StateIcon }] = useService(services.decisionTables.delete);
    const isDeleted = state.status === 'success';
    useEffect(() => {
        if (!onDeleteSuccess || !isDeleted) {
            return;
        }
        const to = setTimeout(() => {
            onDeleteSuccess?.();
        }, 500);
        return () => clearTimeout(to);
    }, [isDeleted, onDeleteSuccess]);
    return (
        <Popup
            renderDialogContent={({ closeDialog }) => (
                <div>
                    <DialogTitle>Delete Decision Table {JSON.stringify(decisionTableKey)}?</DialogTitle>
                    <DialogContent>This action cannot be undone.</DialogContent>
                    <DialogActions style={{ justifyContent: 'space-evenly' }}>
                        <Button variant="contained" color="primary" onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button
                            disabled={state.status === 'pending'}
                            onClick={() => requestDelete(decisionTableKey)}
                            style={{ color: theme.palette.error.dark }}
                            endIcon={StateIcon ?? <Delete color="error" />}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </div>
            )}
            renderToggler={({ openDialog }) => (
                <IconButton size="small" onClick={openDialog()} aria-label="Delete">
                    <Delete color="error" />
                </IconButton>
            )}
        />
    );
};
export default DeleteIconPopup;
