import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import React, { lazy, Suspense } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

const Editor = lazy(() => import('./Editor'));
interface EditorCProps {
    source?: string;
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    disabled?: boolean;
    label?: string;
    wysiwygOnly?: boolean;
}
const LazyEditor = (props: EditorCProps) => (
    <WithErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
            <Editor {...props} />
        </Suspense>
    </WithErrorBoundary>
);
export default LazyEditor;
