import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import 'react-grid-layout/css/styles.css';
import WithWidgets from './WithWidgets';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { setPrimaryDash } from 'dashboard2/dashboard-config/user-primary-dashboard/set-primary-dashboard/actions';
import { setCurrentDash } from 'dashboard2/dashboard-config/current-dashboard/actions';
import Grid from 'dashboard2/grid/components/Grid';
import { createSelector } from 'reselect';
import { fromPredicate } from 'fp-ts/lib/Option';
import memoizeOne from 'memoize-one';
import Themed from 'components/Themed';
import Edit from '@material-ui/icons/Edit';
import CasetivitySelect from 'components/CasetivitySelect';
import { getHideDashboardDropdownSelector } from 'util/applicationConfig';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';
import { success } from '@devexperts/remote-data-ts';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';
import Add from '@material-ui/icons/Add';
import UserCan from 'components/UserCan';

const HideDashboardDropdown: React.FunctionComponent<{ children: React.ReactElement<any> }> = (props) => {
    const hideDashboardDropdownExpression = useAppSelector(getHideDashboardDropdownSelector);
    const shouldHide = useSingleKeyCachingExpression(hideDashboardDropdownExpression ?? false, {}, false);
    if (shouldHide) {
        return null;
    }
    return props.children;
};

interface DashboardProps {}
const makeMapStateToProps = () => {
    const getDashboardNameFromId = createSelector(
        (state: RootState, props: { dashboardId: string }) => props.dashboardId,
        (state: RootState, props: { dashboardId: string }) => state.dashboard.dashboards.configs,
        (dashboardId, dashConfigs) => {
            const matchingDash = Object.entries(dashConfigs).find(([name, dc]) => dc.id === dashboardId);
            return matchingDash && matchingDash[0];
        },
    );
    const allDashboardNamesSelector = createSelector(
        (state: RootState) => state.dashboard.dashboards.allDashboardNames,
        (allDashNames) => deserialize(allDashNames),
    );
    const mapStateToProps = (state: RootState, props: DashboardProps) => {
        const {
            dashboard: { dashboards, currentDashboard, userPrimaryDashboard },
        } = state;
        return {
            userLogin: state.viewConfig && state.viewConfig.user.login,
            allDashboardNames: allDashboardNamesSelector(state),
            allConfigs: dashboards.configs,
            userPrimaryDashboard: deserialize(userPrimaryDashboard).map((dashboardId) =>
                dashboardId
                    ? {
                          id: dashboardId,
                          name: getDashboardNameFromId(state, { dashboardId }),
                      }
                    : false,
            ),
            currentDashboardName: currentDashboard === -1 ? null : currentDashboard,
            currentDashboardId:
                currentDashboard === -1 || !dashboards.configs[currentDashboard]
                    ? null
                    : dashboards.configs[currentDashboard].id,
        };
    };
    return mapStateToProps;
};

const dispatches = {
    updatePrimaryDash: setPrimaryDash,
    setCurrentDashboard: setCurrentDash,
};
type Dispatches = typeof dispatches;

interface DashboardComponentProps
    extends DashboardProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        Dispatches {}
class Dashboard extends React.Component<DashboardComponentProps> {
    initialPrimaryDash?: false | { id: string; name: string };
    componentDidMount(): void {
        const { userPrimaryDashboard } = this.props;
        if (userPrimaryDashboard.isSuccess()) {
            this.initialPrimaryDash = userPrimaryDashboard.value;
        }
    }
    _memoIncludes = memoizeOne((arr: string[], searchElement: string) => {
        return arr && arr.includes(searchElement);
    });
    handleDashboardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.setCurrentDashboard(event.target.value);
    };
    getCurrentDashboardName = () => {
        const { currentDashboardName, userPrimaryDashboard, allDashboardNames } = this.props;

        return (
            currentDashboardName ||
            (userPrimaryDashboard.isPending() && typeof this.initialPrimaryDash !== 'undefined'
                ? success(this.initialPrimaryDash)
                : userPrimaryDashboard
            )
                .toOption()
                .chain((d) =>
                    allDashboardNames.toOption().map(
                        (dashboardNames) =>
                            ({
                                userPrimaryDashboard: d,
                                allDashNames: dashboardNames,
                            } as const),
                    ),
                )
                .fold(undefined, ({ userPrimaryDashboard, allDashNames }) => {
                    if (userPrimaryDashboard && this._memoIncludes(allDashNames, userPrimaryDashboard.name)) {
                        return userPrimaryDashboard.name;
                    }
                    return allDashNames[0];
                })
        );
    };
    getCurrDashId = () => {
        return this.props.currentDashboardId || this.props.allConfigs[this.getCurrentDashboardName()].id;
    };
    setPrimaryDashboardAsCurrent = () => {
        this.props.updatePrimaryDash(this.getCurrDashId());
    };
    userIsAnonymous = () => {
        return !this.props.userLogin || this.props.userLogin === 'anonymousUser';
    };
    render() {
        const { allDashboardNames, userPrimaryDashboard } = this.props;
        const currentDashboardName = this.getCurrentDashboardName();
        if (allDashboardNames.isFailure()) {
            return 'Failed to load dashboards: ' + allDashboardNames.error.message;
        }
        if (userPrimaryDashboard.isFailure()) {
            return 'Failed to get user primary dashboard: ' + userPrimaryDashboard.error.message;
        }
        if (!allDashboardNames.isSuccess() || !currentDashboardName) {
            return <div>Loading...</div>;
        }
        return (
            <div>
                {!this.userIsAnonymous() && (
                    <HideDashboardDropdown>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '5px',
                                paddingBottom: '10px',
                                flexWrap: 'wrap',
                                gap: '.5rem',
                            }}
                        >
                            <Themed>
                                {({ theme }) => (
                                    <span
                                        style={{
                                            color: theme.palette.getContrastText(theme.palette.background.default),
                                        }}
                                    >
                                        Dashboard:
                                    </span>
                                )}
                            </Themed>
                            &nbsp;&nbsp;
                            <CasetivitySelect
                                style={{
                                    backgroundColor: 'unset',
                                    minWidth: '150px',
                                    marginRight: 15,
                                    minHeight: 36,
                                }}
                                aria-label="Select Dashboard"
                                onChange={this.handleDashboardChange}
                                value={currentDashboardName}
                            >
                                {({ OptionComponent }) => {
                                    return allDashboardNames.value.map((key) => (
                                        <OptionComponent key={key} value={key} id={key}>
                                            {key}
                                        </OptionComponent>
                                    ));
                                }}
                            </CasetivitySelect>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.setPrimaryDashboardAsCurrent}
                                style={{
                                    display:
                                        currentDashboardName ===
                                        userPrimaryDashboard
                                            .toOption()
                                            .chain(fromPredicate<{ id: string; name: string }>(Boolean))
                                            .map((d) => d.name)
                                            .getOrElse(null)
                                            ? 'none'
                                            : undefined,
                                }}
                            >
                                Make Default
                            </Button>
                            <UserCan op="edit" resource="Dashboard">
                                <Button to={`/dash-editor2/${this.getCurrDashId()}`} component={Link}>
                                    Edit&nbsp;
                                    <Edit />
                                </Button>
                                <Button to={`dash-creator`} component={Link}>
                                    Create
                                    <Add />
                                </Button>
                            </UserCan>
                        </div>
                    </HideDashboardDropdown>
                )}
                {/* WidgetElements remain mounted between dashboard changes if they are on the next dash,
                    so we have to add key={currentDashboardName} so everything is remounted.
                */}
                <WithWidgets dashboard={currentDashboardName} key={currentDashboardName}>
                    {({ widgetElements, grid }) =>
                        grid && widgetElements && <Grid widgets={widgetElements} grid={grid} />
                    }
                </WithWidgets>
            </div>
        );
    }
}

const enhance = compose(connect(makeMapStateToProps, dispatches));

export default enhance(Dashboard) as React.ComponentType<DashboardProps>;
