import React, { useRef, useEffect, useState, useMemo } from 'react';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import { RootState } from 'reducers/rootReducer';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { FieldTitle } from '../aor/FieldTitle';
import {
    TextField,
    IconButton,
    Paper,
    MenuItem,
    withStyles,
    CircularProgress,
    InputProps as InputPropsType,
    Tooltip,
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import {
    getRestUrl,
    getPluralName,
    getPathBackFromFieldPath,
    allowsCreate,
} from 'components/generics/utils/viewConfigUtils';
import { WithStyles, createStyles, Theme } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import EntityAutocompleteDropdown from './AutocompleteDropdown';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import useTextFieldUtils from 'fieldFactory/input/hooks/useTextFieldUtils';
import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import SearchSelectDialog from 'fieldFactory/popovers/SearchSelectDialog';
import PopoverCreateButton from 'fieldFactory/popovers/PopoverCreateButton';
import useViewConfig from 'util/hooks/useViewConfig';
import CreateDialog from 'fieldFactory/popovers/PopoverRefInput/CreateDialog';
import preprocessFilter from 'clients/utils/preprocessFilter';
import AutoselectIfOnlySingleValue from './AutoselectIfOnlySingleValue';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'scroll',
            maxHeight: 300,
            left: 0,
            minWidth: '100%',
        },
        popoverPaper: {
            maxHeight: 150,
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            maxHeight: 150,
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        loadingSpinner: {
            position: 'absolute',
            right: 2,
            top: 18,
            padding: 0,
        },
    });

export const getFloatingButtonStyle = ({ offsetRight = 0 }: { offsetRight?: number | string }): React.CSSProperties => {
    return {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        right: offsetRight,
    };
};

export interface EntityTypeaheadProps {
    fetchOnMount?: boolean;
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    expansions?: string[];
    filterString?: string;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    isPopover?: boolean;
    label?: string;
    isRequired?: boolean;
    emptyText?: string;
    disabled?: boolean;
    allowEmptyQuery: boolean;
    input: {
        onChange: EventOrValueHandler<string | null>;
        onBlur: EventOrValueHandler<string | null>;
        value: string | null;
    };
    source: string;
    reference: string;
    meta: WrappedFieldMetaProps;
    record?: {};
    search?: string | boolean;
    create?: string | boolean;
    resource?: string;
    resultSize?: number;
    autoSelectValueIfOnlyOneResult?: boolean;
    overrideAriaLabel?: string;
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state: RootState, props: EntityTypeaheadProps) => {
        return {
            restUrl: getRestUrl(props.reference)(state),
            refEntityDisplayNamePlural: getPluralName(state.viewConfig, props.reference),
            currentlySelectedEntity: fromPredicate<string>(Boolean)(props.input.value)
                .chain((id) => fromNullable(state.admin.entities[props.reference]).mapNullable((e) => e[id]))
                .getOrElse(null),
        };
    };
    return mapStateToProps;
};
const dispatches = {
    fetchStart,
    fetchEnd,
    crudGetOne: crudGetOneAction,
    addEntityToStore: (record: { id: string; entityType: string }) => ({
        type: 'ADD_ENTITY_TO_STORE',
        payload: {
            data: {
                entities: {
                    [record.entityType]: {
                        [record.id]: record,
                    },
                },
            },
        },
    }),
};
type Dispatches = typeof dispatches;
interface EntityTypeaheadComponentProps
    extends EntityTypeaheadProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        Dispatches,
        WithStyles<typeof styles> {}

const EntityTypeaheadComponent: React.FunctionComponent<EntityTypeaheadComponentProps> = (props) => {
    const errorMessageId = React.useRef(uniqueId('entity-typeahead'));
    const currentlySelectedEntityTitle = (props.currentlySelectedEntity && props.currentlySelectedEntity.title) || '';
    const [inputValue, setInputValue] = React.useState(currentlySelectedEntityTitle);
    const lastEntityTitle = React.useRef(currentlySelectedEntityTitle);
    React.useEffect(() => {
        if (currentlySelectedEntityTitle !== lastEntityTitle.current && currentlySelectedEntityTitle !== inputValue) {
            if (currentlySelectedEntityTitle) {
                setInputValue(currentlySelectedEntityTitle);
            } else setInputValue('');
        }
        lastEntityTitle.current = currentlySelectedEntityTitle;
    }, [currentlySelectedEntityTitle, setInputValue, inputValue, props.meta.dirty]);
    const [loading, setLoading] = React.useState(false);
    const [key, remountDS] = React.useReducer((state) => state + 1, 1);
    const {
        label,
        meta,
        currentlySelectedEntity,
        restUrl,
        isRequired,
        disabled,
        emptyText = 'None Selected',
        addEntityToStore,
        fetchStart,
        fetchEnd,
        classes,
        isPopover,
        reference,
        dropdownPosition = 'below',
        refEntityDisplayNamePlural,
        source,
        allowEmptyQuery,
        randomizeNameAsBrowserAutocompleteHack = true,
        filterString,
        tooltipText,
        search,
        create,
        resource,
        record,
        resultSize,
        overrideAriaLabel,
    } = props;
    useEffect(() => {
        /*
            Adding this for the scenario where are instantiating a search from URL, and there's some value set.
        */
        if (props.input.value && props.fetchOnMount) {
            props.crudGetOne({
                id: props.input.value,
                resource: reference,
                view: -1,
                // we don't use expansions in search fields, so we don't need expansions
                // appendExpansions: props.expansions
            });
        }
    }, []); // eslint-disable-line
    const uniqueNameToThrowOffChromeAutoFill = useRef(new Date().toISOString());
    const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
    const filterObject = useMemo(() => {
        return getFilterFromFilterString(filterString);
    }, [filterString]);
    const viewConfig = useViewConfig();
    const newFilter = React.useMemo(() => {
        return preprocessFilter(filterObject, viewConfig);
    }, [filterObject, viewConfig]);

    const [createOpen, setCreateOpen] = useState(false);
    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        fieldVariant,
        classnames,
    } = useTextFieldUtils(meta);
    const [searchOpen, setSearchOpen] = useState(false);
    const handleSelect = (record?: { title?: string; id?: string; __create?: boolean }) => {
        if (record?.__create) {
            // handle 'create' popup
            setCreateOpen(true);
            remountDS();
            return;
        }
        if (record) {
            (async () => {
                // make record immediately available
                fetchStart();
                await new Promise((resolve) => setImmediate(resolve));
                addEntityToStore(record as any as { id: string; entityType: string });
                await new Promise((resolve) => setImmediate(resolve));
                fetchEnd();
                setInputValue(record.title);
                // IF we have expansions, we will have to fetch...
                if (props.expansions && props.expansions.length > 0) {
                    setLoading(true);
                    props.crudGetOne({
                        id: record.id,
                        resource: reference,
                        view: -1,
                        appendExpansions: props.expansions,
                        cb: () => {
                            setImmediate(() => {
                                setLoading(false);
                                props.input.onChange(record.id);
                                props.input.onBlur(record.id);
                            });
                        },
                        errorsCbs: {
                            '*': () => {
                                setLoading(false);
                                props.input.onChange(record.id);
                                props.input.onBlur(record.id);
                            },
                        },
                    });
                } else {
                    props.input.onChange(record.id);
                    props.input.onBlur(record.id);
                }
            })();
        } else {
            props.input.onChange(null);
            props.input.onBlur(null);
        }
    };

    const getCreateBackrefs = () => {
        const parentId = (record as any)?.id;
        const pathBack = getPathBackFromFieldPath(
            // returns null if some many-one was on the path, and no relatedField
            viewConfig,
            resource,
            source.endsWith('Id') ? source.slice(0, -2) : source,
        );
        if (parentId && pathBack) {
            return {
                parentEntityName: resource,
                parentId,
                parentFieldInChild: `${pathBack}.id`,
            };
        }
        return {};
    };
    // remove anything that serves purely as a filter.
    const injectCreateValues = Object.fromEntries(
        Object.entries(filterObject).filter(([k]) => {
            const isDottedPathToNonId = k.includes('.') && !(k.split('.').length === 2 && k.endsWith('.id'));
            return !k.includes('__') && !isDottedPathToNonId;
        }),
    );
    const createAllowed = allowsCreate(viewConfig.entities[reference]?.accessLevel);

    const El = (
        <>
            <EntityInspect
                reference={reference}
                formId={`fromentitytypeahead ${source}`}
                renderComponent={(args) => (
                    <div className={classes.root}>
                        <Downshift
                            key={key}
                            inputValue={inputValue}
                            selectedItem={currentlySelectedEntity || ''}
                            onSelect={handleSelect}
                            itemToString={(record) => record.title}
                        >
                            {({
                                inputValue,
                                getInputProps,
                                getLabelProps,
                                getMenuProps,
                                getItemProps,
                                setItemCount,
                                clearItems,
                                selectedItem,
                                highlightedIndex,
                                isOpen,
                                closeMenu,
                                openMenu,
                                clearSelection,
                            }) => {
                                const inputProps = getInputProps({
                                    'aria-label': overrideAriaLabel || label,
                                    'aria-errormessage':
                                        meta.touched && meta.error ? errorMessageId.current : undefined,
                                    placeholder: selectedItem ? selectedItem.title : emptyText,
                                    disabled,
                                    style: {
                                        textOverflow: 'ellipsis',
                                        marginRight: '60px',
                                    },
                                    onChange: (e) => {
                                        setInputValue(e.target.value);
                                    },
                                    onBlur: () => props.input.onBlur(undefined),
                                    onFocus: () => openMenu(),
                                    autoComplete: 'off',
                                });
                                // Downshift automatically injects properties like aria-autocomplete into InputProps
                                // When using Mui TextField, some of these properties should only be on inputProps or only on InputProps
                                // To satisfy Axe let's pull out this property and not apply it to the TextField's InputProps directly
                                const { 'aria-autocomplete': ariaAutocomplete, ...restDownshiftInputProps } =
                                    inputProps;

                                return (
                                    <div className={classes.container}>
                                        <TextField
                                            margin="none"
                                            fullWidth={true}
                                            InputLabelProps={createInputLabelProps({
                                                shrink: true,
                                                ...getLabelProps({
                                                    disabled: false,
                                                }),
                                            })}
                                            variant={fieldVariant}
                                            label={label && <FieldTitle label={label} isRequired={isRequired} />}
                                            InputProps={
                                                {
                                                    inputProps: {
                                                        ...inputProps,
                                                        name: randomizeNameAsBrowserAutocompleteHack
                                                            ? uniqueNameToThrowOffChromeAutoFill.current
                                                            : undefined,
                                                    },
                                                    ...restDownshiftInputProps,
                                                    classes: {
                                                        ...InputPropsClasses,
                                                        root: classnames(InputPropsClasses.root, classes.inputRoot),
                                                    },
                                                    endAdornment: (
                                                        <>
                                                            {loading && (
                                                                <CircularProgress
                                                                    style={{ height: 26, width: 26 }}
                                                                    className={classes.loadingSpinner}
                                                                />
                                                            )}
                                                            {!loading && selectedItem && (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        closeMenu?.();
                                                                        args.selectId(selectedItem.id);
                                                                    }}
                                                                    aria-label="view"
                                                                    style={getFloatingButtonStyle({
                                                                        offsetRight: 0,
                                                                    })}
                                                                >
                                                                    <RemoveRedEye />
                                                                </IconButton>
                                                            )}
                                                            {!loading && !selectedItem && search && !disabled && (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        closeMenu?.();
                                                                        setSearchOpen(true);
                                                                    }}
                                                                    aria-label="search"
                                                                    style={getFloatingButtonStyle({
                                                                        offsetRight: create && createAllowed ? 30 : 0,
                                                                    })}
                                                                >
                                                                    <Search />
                                                                </IconButton>
                                                            )}
                                                            {!loading &&
                                                                !selectedItem &&
                                                                create &&
                                                                createAllowed &&
                                                                !disabled && (
                                                                    <PopoverCreateButton
                                                                        {...getCreateBackrefs()}
                                                                        viewName={
                                                                            typeof create === 'string'
                                                                                ? create
                                                                                : undefined
                                                                        }
                                                                        // remove anything that serves purely as a filter.
                                                                        injectCreateValues={injectCreateValues}
                                                                        renderButton={(props) => (
                                                                            <IconButton
                                                                                {...props}
                                                                                onClick={() => {
                                                                                    closeMenu?.();
                                                                                    props.onClick();
                                                                                }}
                                                                                style={getFloatingButtonStyle({
                                                                                    offsetRight: 0,
                                                                                })}
                                                                            >
                                                                                <Add />
                                                                            </IconButton>
                                                                        )}
                                                                        resource={reference}
                                                                        onCreateCb={(id, data) =>
                                                                            handleSelect(
                                                                                data as { title: string; id: string },
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            {!loading && selectedItem && !disabled && (
                                                                <IconButton
                                                                    style={getFloatingButtonStyle({
                                                                        offsetRight: 30,
                                                                    })}
                                                                    onClick={() => {
                                                                        clearSelection();
                                                                        setInputValue('');
                                                                    }}
                                                                    aria-label="clear"
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            )}
                                                        </>
                                                    ),
                                                } as InputPropsType
                                            }
                                            error={muiErrorProp}
                                            helperText={helperText}
                                            FormHelperTextProps={createFormHelperTextProps(inputProps as any)}
                                        />
                                        <div {...getMenuProps()}>
                                            {isOpen && (
                                                <Paper
                                                    className={
                                                        isPopover
                                                            ? classnames(paperClass, classes.popoverPaper)
                                                            : paperClass
                                                    }
                                                    square={true}
                                                >
                                                    {(() => {
                                                        if (!inputValue && !allowEmptyQuery) {
                                                            return (
                                                                <MenuItem component="div" aria-live="polite" disabled>
                                                                    You have to enter a search query
                                                                </MenuItem>
                                                            );
                                                        }
                                                        return (
                                                            <EntityAutocompleteDropdown
                                                                resultSize={resultSize}
                                                                selectedItem={selectedItem}
                                                                highlightedIndex={highlightedIndex}
                                                                restUrl={restUrl}
                                                                refEntityDisplayNamePlural={refEntityDisplayNamePlural}
                                                                getItemProps={getItemProps}
                                                                inputValue={inputValue}
                                                                filter={newFilter}
                                                                setItemCount={setItemCount}
                                                                createable={Boolean(create)}
                                                            />
                                                        );
                                                    })()}
                                                </Paper>
                                            )}
                                        </div>
                                    </div>
                                );
                            }}
                        </Downshift>
                    </div>
                )}
            />
            <SearchSelectDialog
                createViewName={typeof create === 'string' ? create : undefined}
                viewName={typeof search === 'string' ? search : undefined}
                hideCreate={!create || !createAllowed}
                reference={reference}
                isOpen={searchOpen}
                handleClose={() => setSearchOpen(false)}
                filter={filterObject}
                setReference={(record) => {
                    handleSelect(record as { title: string; id: string });
                    setSearchOpen(false);
                }}
                values={[]}
                onCreateCb={(record) => {
                    handleSelect(record as any);
                }}
            />
            {create && createAllowed && (
                <CreateDialog
                    open={createOpen}
                    injectCreateValues={injectCreateValues}
                    setOpen={setCreateOpen}
                    resource={reference}
                    viewName={typeof create === 'string' ? create : undefined}
                    onCreateCb={(record) => {
                        remountDS();
                        handleSelect(record as any);
                    }}
                    {...getCreateBackrefs()}
                />
            )}
            {props.autoSelectValueIfOnlyOneResult && (
                <AutoselectIfOnlySingleValue
                    filterString={filterString}
                    handleSelect={handleSelect}
                    restUrl={restUrl}
                    input={props.input}
                />
            )}
        </>
    );
    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                <div>{El}</div>
            </Tooltip>
        );
    }
    return El;
};

const EntityTypeahead = compose(connect(makeMapStateToProps, dispatches), withStyles(styles))(EntityTypeaheadComponent);
export default EntityTypeahead;
