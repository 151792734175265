import moment, { Moment } from 'moment';
import sortBy from 'lodash/sortBy';

const startOrEnds = ['startOf', 'endOf'] as const;
export type StartOrEnd = (typeof startOrEnds)[number];
const distances = [0, -1, -2, 1] as const;
export type Distance = (typeof distances)[number];
const periodUnits = ['week', 'month', 'quarter', 'year'] as const;
export type PeriodUnit = (typeof periodUnits)[number];

export const getPlainDateFromPeriodStartEnd = (
    startOrEnd: 'endOf' | 'startOf',
    distance: number,
    unit: PeriodUnit,
): string => {
    const date = moment()[unit](moment()[unit]() + distance) as Moment;
    if (date.isValid()) {
        return date[startOrEnd](unit).format('YYYY-MM-DD');
    }
    return null;
};

/**
 * Given a date, returns the shortest valid period start/end reachable by our widget which results in that date.
 *
 * TODO: add 'prefer' prop, for a preferred unit, to prevent ambiguity.
 * e.g. if the current quarter and the current month are the same.
 */
export const getPeriodStartEndFromPlainDate = (date: string, preferredUnit?: PeriodUnit) => {
    const sortedPeriodUnits = preferredUnit
        ? sortBy(periodUnits, (unit) => (unit === preferredUnit ? 1 : 2))
        : periodUnits;
    console.log({
        preferredUnit,
        sortedPeriodUnits,
    });
    for (const unit of sortedPeriodUnits) {
        for (const distance of distances) {
            for (const startOrEnd of startOrEnds) {
                const foundPlainDate = getPlainDateFromPeriodStartEnd(startOrEnd, distance, unit);
                if (foundPlainDate === date) {
                    console.log('returning ', [startOrEnd, distance, unit]);
                    return [startOrEnd, distance, unit] as const;
                }
            }
        }
    }
    return null;
};

const HEADER = 'period:';

export const encodePeriodStartEnd = (date: string, preferredUnit?: PeriodUnit) => {
    const attemptedEncoding = getPeriodStartEndFromPlainDate(date, preferredUnit);
    if (!attemptedEncoding) {
        return null;
    }
    const [startOrEnd, distance, unit] = attemptedEncoding;
    return `${HEADER}${JSON.stringify(startOrEnd)}|${JSON.stringify(distance)}|${JSON.stringify(unit)}`;
};

export const decodePeriodStartEnd = (encodedPeriodStartEnd: string) => {
    if (isPeriodStartEndEncoding(encodedPeriodStartEnd)) {
        const [startOrEnd, distance, unit] = encodedPeriodStartEnd
            .slice(HEADER.length)
            .split('|')
            .map((text) => JSON.parse(text)) as [StartOrEnd, Distance, PeriodUnit];
        return getPlainDateFromPeriodStartEnd(startOrEnd, distance, unit);
    }
    return null;
};

export const isPeriodStartEndEncoding = (str: unknown): str is string => {
    if (typeof str !== 'string') {
        return false;
    }
    if (!str.startsWith(HEADER)) {
        return false;
    }
    const parts = str.split('|');
    return parts.length === 3;
};
