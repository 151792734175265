import useViewConfig from 'util/hooks/useViewConfig';
import React from 'react';

type OpInput = 'read' | 'create' | 'edit' | 'merge' | 'delete' | 1 | 2 | 3 | 4 | 5;
interface UserCanProps {
    op: OpInput;
    resource: string;
}
const getAccessLevel = (opInput: OpInput): number => {
    switch (opInput) {
        case 'read':
            return 1;
        case 'create':
            return 2;
        case 'edit':
            return 3;
        case 'merge':
            return 4;
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            return opInput;
        default:
            return Infinity;
    }
};
const UserCan: React.FC<UserCanProps> = (props) => {
    const accessLevelToView = getAccessLevel(props.op);
    const viewConfig = useViewConfig();
    const usersAccessLevelForResource = viewConfig.entities[props.resource]?.accessLevel ?? 0;
    if (accessLevelToView > usersAccessLevelForResource) {
        return null;
    }
    return <>{props.children}</>;
};

export default UserCan;
