import React, { useState } from 'react';
import { FormControlLabel, Switch, TextareaAutosize } from '@material-ui/core';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
/**
 * Decode characters like &amp; which mess up e.g. $=[my && spel] or ${foo && bar}
 */
function htmlDecode(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}
const htmlUnescapeSPELTags =
    ({
        bracketClosesOn,
        bracketNaivelyOpensOn,
        bracketOpensOn,
    }: {
        bracketOpensOn: string;
        bracketClosesOn: string;
        bracketNaivelyOpensOn: string;
    }) =>
    (template: string) => {
        const parse = getNextBracketedExpression({
            bracketClosesOn,
            bracketNaivelyOpensOn,
            bracketOpensOn,
        });
        const evalString = (str: string) =>
            parse(str).fold(str, ({ before, inner, after }) => {
                // We remove whitespace here, so we can add it back from 'inner' if we really want.
                return before + `${bracketOpensOn}${htmlDecode(inner)}${bracketClosesOn}` + evalString(after);
            });
        return evalString(template);
    };

const ModedHtmlEditor: React.FC<{
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement> | string) => void;
    onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
    wysiwygOnly?: boolean;
}> = (props) => {
    const [wysiwygMode, setWysiwygMode] = useState(!!props.wysiwygOnly);

    const handleWysiwygChange = (e: React.ChangeEvent<HTMLTextAreaElement> | string) => {
        const value = typeof e === 'string' ? e : e.target.value;
        props.onChange(
            htmlUnescapeSPELTags({
                bracketOpensOn: '$=[',
                bracketNaivelyOpensOn: '[',
                bracketClosesOn: ']',
            })(
                htmlUnescapeSPELTags({
                    bracketOpensOn: '${',
                    bracketNaivelyOpensOn: '{',
                    bracketClosesOn: '}',
                })(value),
            ),
        );
    };
    return (
        <div>
            {!props.wysiwygOnly && (
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            checked={wysiwygMode}
                            onChange={(e) => setWysiwygMode(e.target.checked)}
                            defaultChecked
                        />
                    }
                    label="Wysiwyg"
                />
            )}
            {wysiwygMode ? (
                <TinyMCEEditor
                    init={{
                        convert_unsafe_embeds: true as any,
                        forced_root_block: false as any,
                        branding: false,
                        plugins: 'autoresize',
                        entity_encoding: 'raw',

                        relative_urls: false,
                        convert_urls: false,
                        verify_html: false,
                        cleanup_on_startup: false,
                        cleanup: false,
                        validate_children: false,
                        remove_redundant_brs: false,
                        remove_linebreaks: false,
                        force_p_newlines: false,
                        force_br_newlines: false,
                        fix_table_elements: false,
                    }}
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    value={props.value}
                    onEditorChange={(e) => handleWysiwygChange(e)}
                />
            ) : (
                <div>
                    <TextareaAutosize
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        style={{ width: '100%' }}
                    />
                </div>
            )}
        </div>
    );
};
export default ModedHtmlEditor;
