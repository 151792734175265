import React, { useEffect, useState } from 'react';
import { Button, Card, Typography, useTheme } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import ControlledDecisionTableEditor from 'decision-table-editor/ControlledDecisionTableEditor';
import { DecisionTable } from 'decision-table-editor/domain';
import { services } from 'sideEffect/services';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import useService from 'util/hooks/useService';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';

let initialTable: DecisionTable = {
    modelVersion: '3',
    id: '',
    key: '',
    name: '',
    description: null,
    hitIndicator: 'FIRST',
    inputExpressions: [
        {
            id: '1',
            variableId: '',
            label: 'Untitled Input',
            type: 'string',
        },
    ],
    outputExpressions: [
        {
            id: '1',
            variableId: '',
            label: 'Untitled Output',
            type: 'string',
        },
    ],
    rules: [{}],
};

const CreateDecisionTable = () => {
    const [state, setState] = useState<DecisionTable>(initialTable);
    const dispatch = useDispatch();
    const theme = useTheme();

    const key = state.key;
    const [submissionState, submit, { StateIcon: SubmissionStateIcon }] = useService(services.decisionTables.create);
    useEffect(() => {
        if (submissionState.status === 'success') {
            dispatch(push(`/admin/bpm/decision-table/edit/${key}`));
        }
    }, [submissionState, key, dispatch]);

    return (
        <Card style={{ padding: '.5em' }}>
            <div style={{ display: 'flex' }}>
                <Button size="small" component={Link} to="/admin/bpm/decision-table" startIcon={<ArrowBack />}>
                    Back
                </Button>
                <div style={{ marginLeft: '1em' }}>
                    <Typography variant="h5">Create Decision Table</Typography>
                </div>
            </div>
            <div>
                <ControlledDecisionTableEditor mode="create" state={state} setState={setState} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!state.key || !state.name || submissionState.status === 'pending'}
                        endIcon={SubmissionStateIcon ?? <Save />}
                        onClick={() => {
                            const { id, ...rest } = state;
                            submit(state.key, rest as any);
                        }}
                    >
                        Create
                    </Button>
                </div>
            </div>
            {submissionState.status === 'error' ? (
                <div style={{ margin: theme.spacing(1) }}>
                    <Alert severity="error">
                        <AlertTitle>An error occurred</AlertTitle>
                        <p>{submissionState.error.response?.description ?? null}</p>
                    </Alert>
                </div>
            ) : null}
        </Card>
    );
};

export default CreateDecisionTable;
