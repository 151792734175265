import React from 'react';
import * as fieldTypes from '../fieldTypes';
// import RefmanyMultiselectInput from './components/RefmanyMultiselect';
import RefManyMultiselect from '../display/components/RefmanyMultiselect';
import generateMemberField, { overrideFieldValueIfDisabled } from './generateMemberField';
import { withEvaluatedFilter } from './inputHigherOrderComponents/withEvaluatedFilter';
import { Field, EntityRefManyField, EntityMultiCardField, EntityInlineManyField } from '../translation/types/index';
import { getConfigProperty, isEntityDataField, isFieldFromEntity, simpleGetConfProperty } from './fieldUtils/index';
import { getAriaSupportProperties } from 'fieldFactory/ariaSupport';
import EvaluateInEntityContext from 'expressions/components/EvaluateExpressionInEntityContext';
import {
    getEditViewName,
    getOpenToSPEL,
    getOverrideNavButtonHtml,
    getShowViewName,
    getViewName,
} from './fieldUtils/reference';
import OfflineXMany, { useOfflineData } from 'fieldFactory/offline/XMany';
import isOffline from 'util/isOffline';
import getRenderMultiCardList from 'fieldFactory/display/components/MultiCard/renderMultiCardList';
import getRenderInlineList from './components/InlineMany/renderInlineManyList';
import ScrollToFieldFlag from 'fieldFactory/ScrollToField/ScrollToFieldFlag';
import { FormName } from 'redux-form';
import { withTemplatableOpenToExpression } from './inputHigherOrderComponents/withTemplatableOpenToExpression';

const isEntityRefManyField = (fieldDefinition: Field): fieldDefinition is EntityRefManyField =>
    fieldDefinition._dataSource === 'Entity' && fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT;

const isEntityInlineManyField = (fieldDefinition: Field): fieldDefinition is EntityInlineManyField =>
    fieldDefinition._dataSource === 'Entity' && fieldDefinition.type === fieldTypes.INLINE_MANY;

const isEntityMultiCardField = (fieldDefinition: Field): fieldDefinition is EntityMultiCardField =>
    fieldDefinition._dataSource === 'Entity' && fieldDefinition.type === fieldTypes.MULTI_CARD;

const isEntityRefManyOrMultiCardField = (
    fieldDefinition: Field,
): fieldDefinition is EntityMultiCardField | EntityMultiCardField =>
    fieldDefinition._dataSource === 'Entity' &&
    (fieldDefinition.type === fieldTypes.MULTI_CARD ||
        fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT ||
        fieldDefinition.type === fieldTypes.INLINE_MANY);

export default (
    // inner field component level
    fieldDefinition: Field,
    viewConfig,
    configuration,
    liveProps,
) => {
    let propConfiguration: any = {
        'data-originaldefinition': fieldDefinition['data-originaldefinition'],
        source: fieldDefinition.name,
        label: fieldDefinition.label,
        row: fieldDefinition.row,
        span: fieldDefinition.span,
        column: fieldDefinition.column,
    };
    let RComponent;
    // key for the unique widget - "source" won't be unique if there are multiple fields pointing to the same piece of data
    const fieldInstanceIdentifier =
        fieldDefinition._dataSource === 'Entity' && fieldDefinition.fieldInstanceIdentifier
            ? fieldDefinition.fieldInstanceIdentifier
            : propConfiguration.source;
    // treating this as something that may be extended with more datatypes in the future
    if (
        fieldDefinition.type === fieldTypes.MULTI_CARD ||
        fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT ||
        fieldDefinition.type === fieldTypes.REFMANY_JOIN_MULTISELECT ||
        fieldDefinition.type === fieldTypes.INLINE_MANY ||
        (fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT_IDSLIST &&
            (isOffline() ||
                // When we first download, OR when we 'apply' the data.
                window['casetivity_store'].getState().taskCurrentlyWritingToOffline))
    ) {
        const hasCreate = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('hasCreate')(fieldDefinition).getOrElse(true)
            : true;
        const hasAdd = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('hasAdd')(fieldDefinition).getOrElse(false)
            : true;
        const hasEdit = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('hasEdit')(fieldDefinition).getOrElse(true)
            : true;

        const disallowClickAwayNavigation = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('disallowClickAwayNavigation')(fieldDefinition).toUndefined()
            : undefined;
        const noClick = isEntityRefManyField(fieldDefinition)
            ? getConfigProperty('noClick')(fieldDefinition).getOrElse(false)
            : false;

        const filter = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('filter')(fieldDefinition).toUndefined()
            : false;
        const includeTopCreateButton = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty<{ includeTopCreateButton?: boolean }>('includeTopCreateButton')(
                  fieldDefinition,
              ).getOrElse(false)
            : false;
        const openToEditAfterCreate = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('openToEditAfterCreate')(fieldDefinition).toUndefined()
            : undefined;

        const overrideNavButtonHtml = isEntityRefManyField(fieldDefinition)
            ? getOverrideNavButtonHtml(fieldDefinition).toUndefined()
            : undefined;

        const defineSPELVariables = isEntityRefManyOrMultiCardField(fieldDefinition)
            ? getConfigProperty('defineSPELVariables')(fieldDefinition).getOrElse(null)
            : null;

        const XManyComponent = (props) => {
            /**
             * This component is duplicated in display/RefmanyMultiselect/XManyComponent.tsx
             * but don't import it yet - doing so causes a dependency cycle problem
             * go to: qa.vaei /processes?filter=%7B"assignee_ANY"%3Atrue%2C"processInstance.businessKey"%3A"ei_enrollment"%2C"processInstance.endTime__NOT_EMPTY"%3Afalse%2C"linkedEnrollment.enrollmentNumber__NOT_EMPTY"%3Atrue%7D&order=ASC&page=1&perPage=10&sort=processInstance.startTime
             */
            const { record, source } = props;
            const { id, entityType } = record;

            const [entriesAtCurrentNesting] = useOfflineData(entityType, source, id);
            if (
                isOffline() ||
                entriesAtCurrentNesting?.some((e) => e.source === source || source === `${e.source}Ids`)
            ) {
                return <OfflineXMany {...props} />;
            }
            return <RefManyMultiselect {...props} />;
        };

        let InnerComponent = filter
            ? withEvaluatedFilter(
                  ({ filterString, ...props }: any) => <XManyComponent {...props} filter={filterString} />,
                  filter,
                  'Entity',
                  fieldDefinition['data-originaldefinition'],
              )
            : XManyComponent;

        RComponent = (props) => {
            return (
                <EvaluateInEntityContext
                    expression={defineSPELVariables || null}
                    defaultOnException={false}
                    useLiveValues
                >
                    {({ expressionResult: evaluatedAdhocSPELVariables }) => (
                        <EvaluateInEntityContext expression={hasAdd} defaultOnException={false}>
                            {({ expressionResult: hasAddResult }) => (
                                <EvaluateInEntityContext expression={hasCreate} defaultOnException={true}>
                                    {({ expressionResult: hasCreateResult }) => (
                                        <InnerComponent
                                            {...props}
                                            hasAdd={hasAddResult}
                                            // possibly make this a lazy 'getSpelVariables' prop so we don't rerender whenever that chenges? let's see.
                                            evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                            hasCreate={hasCreateResult}
                                            isDynamicallyTemplatedFilter={filter && filter?.includes('$[')}
                                        />
                                    )}
                                </EvaluateInEntityContext>
                            )}
                        </EvaluateInEntityContext>
                    )}
                </EvaluateInEntityContext>
            );
        };

        let openTo = isFieldFromEntity(fieldDefinition)
            ? getConfigProperty('openTo')(fieldDefinition).getOrElse('show')
            : undefined;
        if (openTo === 'SPEL Expression') {
            const expressionTemplate = getOpenToSPEL(fieldDefinition);
            const InnerComponent = RComponent;
            RComponent = withTemplatableOpenToExpression(expressionTemplate, InnerComponent);
            openTo = undefined;
        }

        propConfiguration = {
            ...propConfiguration,
            openToEditAfterCreate,
            hasPopoverSearch: false, // fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT,
            hasTable: true,
            ariaInputProps: {},
            hidePaginationIfNotNeeded: simpleGetConfProperty<{ hidePaginationIfNotNeeded?: boolean }>(
                'hidePaginationIfNotNeeded',
                false,
            )(fieldDefinition),
            // being on the create view means there are no links - we've already committed to creating new records
            createableIntermediateLinkIfMissing:
                liveProps.isForCreate ||
                simpleGetConfProperty<{
                    createableIntermediateLinkIfMissing?: boolean;
                }>(
                    'createableIntermediateLinkIfMissing',
                    false,
                )(fieldDefinition),
            disallowClickAwayNavigation,
        };

        if (fieldDefinition.type === fieldTypes.MULTI_CARD) {
            const config = {
                viewName: getViewName(fieldDefinition) as string,
                showViewName: getShowViewName(fieldDefinition) as string,
                editViewName: getEditViewName(fieldDefinition) as string,
                hasCreate: getConfigProperty('hasCreate')(fieldDefinition).getOrElse(false),
                hasEdit: getConfigProperty('hasEdit')(fieldDefinition).toUndefined(),
                openTo,
                label: propConfiguration.label,
            };
            propConfiguration.noEmptyTable = true;
            propConfiguration.overrideRenderList = getRenderMultiCardList(config);
            propConfiguration.titleElement = <h2>{propConfiguration.label}</h2>;
        }

        if (fieldDefinition.type === fieldTypes.INLINE_MANY) {
            const useCreateView = simpleGetConfProperty<{ useCreateView?: boolean }>(
                'useCreateView',
                false,
            )(fieldDefinition);
            const createViewName = simpleGetConfProperty<{ createViewName?: string }>(
                'createViewName',
                undefined,
            )(fieldDefinition);
            const unchangingOrderedItems = simpleGetConfProperty<{ unchangingOrderedItems?: boolean }>(
                'unchangingOrderedItems',
                false,
            )(fieldDefinition);
            const config = {
                viewName: getViewName(fieldDefinition) as string,
                id: liveProps?.record?.id,
                source: propConfiguration.source,
                entityType: liveProps?.record?.entityType,
                openTo,
                useCreateView,
                createViewName,
                unchangingOrderedItems,
            };
            propConfiguration.overrideRenderList = getRenderInlineList(config);
            propConfiguration.noPagination = true;
            propConfiguration.inlineMany = {
                useCreateView,
                createViewName,
            };
            const X = overrideFieldValueIfDisabled('Entity')(RComponent);
            RComponent = X;
        }
        const ariaSupportProps = getAriaSupportProperties(fieldDefinition.row, fieldDefinition.column, fieldDefinition);

        if (ariaSupportProps && ariaSupportProps.labelledBy) {
            propConfiguration.ariaInputProps['aria-labelledby'] = ariaSupportProps.labelledBy;
            propConfiguration.renderLabel = false;
        }
        const OldComponent = RComponent;

        const renderScrollTo = (props: { form: string }) => {
            return props.form ? <ScrollToFieldFlag formId={props.form} source={propConfiguration.source} /> : null;
        };
        const RComponentWithScrollFlag = (props) => {
            return (
                <>
                    <FormName>{renderScrollTo}</FormName>
                    <OldComponent {...props} />
                </>
            );
        };
        RComponent = RComponentWithScrollFlag;
        return (
            <RComponent
                {...propConfiguration}
                fieldInstanceIdentifier={fieldInstanceIdentifier}
                openTo={openTo}
                overrideNavButtonHtml={overrideNavButtonHtml}
                includeTopCreateButton={includeTopCreateButton}
                config={
                    isEntityRefManyField(fieldDefinition) ||
                    isEntityInlineManyField(fieldDefinition) ||
                    // because manymanys are new offline, we need to pass 'config' for the OfflineXMany component.
                    (isEntityDataField(fieldDefinition) &&
                        fieldDefinition.type === fieldTypes.REFMANY_MULTISELECT_IDSLIST)
                        ? fieldDefinition.config
                        : undefined
                }
                hasEdit={hasEdit}
                noClick={noClick}
                key={`${propConfiguration.source}-refmany (${propConfiguration.label})`}
                addField={true}
                dontConnect={true}
                {...liveProps}
            />
        );
    }
    return generateMemberField(fieldDefinition, viewConfig, configuration, liveProps);
};
